<template>
    <modal ref="modalDescripcionLeecheros" :titulo="$config.vendedor" tamano="modal-lg" no-adicional no-cancelar>
        <div class="row mx-0 j-center">
            <div class="col-auto px-0">
                <img :src="infoLeechero.logo" class="br-8 obj-cover border" width="142" height="142" />
            </div>
            <div class="col-6">
                <div class="row mx-0">
                    <p class="col-auto px-0 f-500 f-22">
                        {{ infoLeechero.nombre }}
                    </p>
                    <div class="col-auto px-0 ml-auto d-middle f-15">
                        <i class="icon-star text-yellow" />
                        <span class="ml-2 f-500">{{ infoLeechero.calificacion }}</span>
                        <span>({{ infoLeechero.calificacion_cantidad }})</span>
                    </div>
                </div>
                <p class="f-14 my-2">
                    {{ infoLeechero.descripcion }}
                </p>
                <div class="row mx-0 align-items-center mt-2">
                    <div class="col-auto px-0 d-middle px-0">
                        <i class="icon-truck-outline mr-2" />
                        <span v-if="infoLeechero.domicilio_gratis == 0" class="f-15">Domicilio Gratis</span>
                        <span v-else class="f-15"> {{ formatNumero(infoLeechero.domicilio_gratis, 0, true) }}</span>
                    </div>
                    <div class="col-auto ml-auto px-0 d-middle">
                        <i class="icon-map-pin f-17" />
                        <span class="f-15">A <span class="f-500">{{ formatNumero(infoLeechero.distancia, 0, false) }} m.</span> de ti </span>
                    </div>
                </div>
                <div class="row mx-0 j-center mt-3">
                    <div class="col-12 px-0">
                        <div class="btn-general" @click="cambiarLeechero()">
                            Cambiar
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Leechero from '~/services/leechero/leechero'
export default {
    data(){
        return {
            infoLeechero: {},
        }
    },
    methods: {
        toggle(){
            this.$refs.modalDescripcionLeecheros.toggle();
            this.datoLeechero();
        },
        async datoLeechero(){
            try {
                const { data } = await Leechero.getDataLeechero();
                this.infoLeechero = data.datosLeechero;
            } catch (e){
                this.errorCatch(e)
            }          
        },
        cambiarLeechero(){
            this.$emit('cambiarLeechero')
            this.$refs.modalDescripcionLeecheros.toggle();
        }
    }
}
</script>
